
.pagination-button {
  background-color: #00857d; 
  color: white;
  border: none;
  cursor: pointer;
  padding: 0.15rem 0.5rem; 
  font-size: 0.9rem; 
  height: 1.8rem; 
}

.pagination-button:hover {
  background-color: #006c63; 
}

.pagination-info {
  color: #00857d;
  padding: 0.2rem 0.7rem;
  font-size: 0.88rem; 
}

.download-button {
  color: #0e2554;
  background-color: var(--eori-yellow);
  padding: 0.5rem 0.75rem;
  width: 7rem;
  border-radius: 0.9rem;
  font-size: 1.1rem;
  line-height: 1.2rem;
  cursor: pointer;
  border: none;
}

.clear-button {
  color: #f3f4f6;
  background-color: var(--eori-red);
  padding: 0.5rem 0.75rem;
  width: 7rem;
  border-radius: 0.9rem;
  font-size: 1rem;
  line-height: 1rem;
  cursor: pointer;
  border: none;
}

.clear-button.inactive {
  /* color: #565657; */
  background-color: #9e9e9e;
  cursor: not-allowed; 
}